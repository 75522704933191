import { ft } from '~/utils/feature-translate';
import type { SupportedPages } from './types';

export const ru = {
  sidebar: {
    static_table_title: 'Статичные страницы',
    dynamic_table_title: 'Динамичные страницы',
    title: {
      index: 'Главная',
      // контентные
      delivery: 'Доставка',
      payment: 'Оплата',
      'obmen-vozvrat-tovarov': 'Обмен и возврат товаров',
      privacy: 'Политика конфиденциальности',
      public_offer: 'Публичная оферта',
      agreement: 'Согласие на обработку персональных данных',
      requirements: 'Требования по нанесению',
      about: 'О компании',
      vacancy: 'Вакансии',
      // служебные
      comparison: 'Сравнение',
      favorites: 'Избранное',
      contacts: 'Контакты',
      news: 'Новости',
      blog: 'Блог',
      promotions: 'Акции',
      print: 'Варианты нанесения',
      catalog: 'Каталог',
      brands: 'Бренды',
      newones: 'Новинки',
      recommended: 'Мы рекомендуем',
      bestoffers: 'Лучшие предложения',
      cart: 'Корзина',
      profile: 'Профиль',
      companies: 'Юридические лица в профиле',
      orders: 'Заказы в профиле',
      ordering: 'Оформление заказа',
      kp: 'Конструктор предложений',
      search: 'Поиск',
      faq: 'Часто задаваемые вопросы (F.A.Q.)',
      // Динамические
      error: '404 страница',
      category: 'Страница категории',
      brand: 'Страница бренда',
      product: 'Страница товара',
      'blog-post': 'Статья блога',
      'news-post': 'Новостная статья',
      'print-post': 'Вариант нанесения',
      'profile-kp': 'Конструктор предложений в профиле',
      'promotion-post': 'Акция',
    } as Record<SupportedPages, string>,
  },
  table: {
    dynamic_title: 'SEO - Динамичные страницы',
    static_title: 'SEO - Статичные страницы',
    page_title: 'Название страницы',
  },
  form: {
    header: 'Редактирование SEO',
    title: { label: 'Название страницы (Title)' },
    meta_title: { label: 'Название страницы (Title)' },
    h1: { label: 'Заголовок (H1)' },
    description: { label: 'Описание (Description)', placeholder: '' },
    keywords: {
      label: 'Ключевые слова (Keywords)',
      hint: 'Вводите ключевые слова, разделяя их запятой',
      placeholder: 'Too many gifts, tmg, e-commerce',
    },
  },
};

export const t = ft(ru, 'settings.seo.');
